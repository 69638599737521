import {graphql} from 'gatsby'
import ArticlePage from '../article-page'

export default ArticlePage

export const pageQuery = graphql`
  query ArticleEnPageByID($id: String!, $category: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allCategoriesYaml(filter: {data: {elemMatch: {lang: {eq: "en"}}}}) {
      edges {
        node {
          data {
            category
            name
            icon
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        published_date(formatString: "MMMM DD, YYYY")
        modified_date(formatString: "MMMM DD, YYYY")
        title
        description
        category
        tags
        lang
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___index], order: ASC},
      filter: {frontmatter: {templateKey: { eq: "article-page" }, category: {eq: $category}, invisible:{ne: true}}}
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              index
              title
            }
          }
        }
      }
    }    
  }
`